import _ from "lodash";

const getTableCodes = (tableCodeRanges) => {
  const splitValues = _.split(_.trim(tableCodeRanges), ",");
  return _.reduce(
    splitValues,
    (tableCodes, value) => {
      if (!_.includes(value, "-")) {
        tableCodes.push(value);
      } else {
        const range = _.split(value, "-");
        const start = _.toNumber(range[0]);
        const end = _.toNumber(range[1]) + 1;
        _.forEach(_.range(start, end), (code) =>
          tableCodes.push(_.toString(code)),
        );
      }
      return tableCodes;
    },
    [],
  );
};

export const getTableCodeValidator = (branchAvailability) => (code) => {
  const tableCodes = _.get(branchAvailability, "tableCodeRanges")
    ? getTableCodes(branchAvailability.tableCodeRanges)
    : [];
  if (_.isEmpty(tableCodes)) {
    return true;
  }
  if (!code || !_.includes(tableCodes, code)) {
    return false;
  }
  return true;
};
