import { Avatar, Badge, Chip, Grid } from "@mui/material";
import { makeStyles } from "../AppContainer/mui-theme";
import EmojiFoodBeverageIcon from "@mui/icons-material/RestaurantMenu";
import { get } from "lodash";
import React from "react";
import { cdnRoot } from "../../utils/media";
import * as styles from "./index.module.scss";

const getCategoryImage = (category) => {
  const imageKey = _.get(category, "imageKey");

  return (
    imageKey &&
    `${cdnRoot}/menu-categories/${_.get(category, "imageKey")}/300-_-contain_v4`
  );
};

export default function CategoryLabel({
  category,
  showCount,
  appStyles = {},
  isCurrent,
  variant = "circular",
  refEl,
  T
}) {
  const { classes } = useStyles();
  const displayCategoriesImages = appStyles.displayCategoriesImages;
  const flexAlign = displayCategoriesImages ? "center" : "start";
  const textAlign = displayCategoriesImages ? "center" : "left";

  return (
    <Grid
      ref={refEl}
      container
      direction="column"
      alignItems={flexAlign}
      spacing={1}
    >
      {appStyles.displayCategoriesImages && (
        <Grid item>
          <Badge
            invisible={!showCount}
            overlap="circular"
            color="primary"
            badgeContent={showCount ? get(category, "items.length", 0) : "0"}
          >
            <Avatar
              variant={variant}
              src={getCategoryImage(category)}
              className={classes.avatar}
            >
              <EmojiFoodBeverageIcon />
            </Avatar>
          </Badge>
        </Grid>
      )}
      <Grid item alignContent={flexAlign}>
        <div
          style={{
            textAlign,
            ...(isCurrent && {}),
            ...appStyles.CategoryMenuLabel,
          }}
        >
          {T(category.name)}
          {Boolean(showCount) && !appStyles.displayCategoriesImages && (
            <>
              {" "}
              <Chip label={category.items.length} />
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles()((theme) => ({
  avatar: {
    height: 80,
    width: 80,
    [theme.breakpoints.down("md")]: {
      height: 60,
      width: 60,
    },
  },
}));
